"use client";

import clsx from "clsx";

import useModal from "@/lib/hooks/useModal";
import useSessionStore from "@/lib/stateManagement/sessionState/sessionStore";

import styles from "./modal.module.css";

const ClientModal = ({
  modalName,
  children,
  isMenu,
}: {
  modalName: string;
  isMenu?: boolean;
  children: React.ReactNode;
}): React.JSX.Element => {
  const { isOpen, closeModal } = useModal(modalName, () => {});
  const atTop = useSessionStore((store) => store.atTop);

  return (
    <div
      data-open={isOpen}
      className={clsx(
        styles.container,
        !isOpen && styles.hidden,
        isMenu && styles.menu,
        isMenu && !atTop && styles.scrolled
      )}
    >
      <div
        className={clsx(styles["mobile-overlay"], isOpen && styles.active)}
        onClick={closeModal}
      />
      {children}
    </div>
  );
};

export default ClientModal;
